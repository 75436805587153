import { ANALYTICS_TABS } from 'constants/constants';
import { ROUTES } from 'router/constants';

export const initialOptions = {
  options: [],
  loading: true,
};

export const ITEM_PER_PAGE = [
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 70, label: 70 },
];

export const TABS = {
  RECRUITERS: 'recruiters',
  TEAM_LEADS: 'team leads',
  CANDIDATES: 'candidates',
  REPORTING: 'reporting',
  OPENINGS: 'openings',
};

export const CANDIDATES_TABLE_OPTIONS = {
  count: 0,
  next: false,
  prev: false,
  offset: 0,
  limit: 100,
  sorting: { key: 'job', order: 'asc', label: 'Job', field: 'Job' },
  pageToShow: 1,
};

export const OPTIONS = [
  { label: 'Recruiters', key: ANALYTICS_TABS.RECRUITERS },
  { label: 'Team Leads', key: ANALYTICS_TABS.TEAM_LEADS },
  { label: 'Jobs', key: ANALYTICS_TABS.JOBS },
  { label: 'Candidates', key: ANALYTICS_TABS.CANDIDATES },
  { label: 'Openings', key: ANALYTICS_TABS.OPENINGS },
  { label: 'Reporting', key: ANALYTICS_TABS.REPORTING },
  { label: 'Self-submitted candidates', key: ANALYTICS_TABS.SSC },
];

export const PATHS = {
  [ANALYTICS_TABS.RECRUITERS]: ROUTES.ANALYTICS_RECRUITERS,
  [ANALYTICS_TABS.TEAM_LEADS]: ROUTES.ANALYTICS_TEAM_LEADS,
  [ANALYTICS_TABS.JOBS]: ROUTES.ANALYTICS_JOB,
  [ANALYTICS_TABS.CANDIDATES]: ROUTES.ANALYTICS_CANDIDATE,
  [ANALYTICS_TABS.REPORTING]: ROUTES.ANALYTICS_REPORTING,
  [ANALYTICS_TABS.OPENINGS]: ROUTES.ANALYTICS_OPENINGS,
  [ANALYTICS_TABS.SSC]: ROUTES.ANALYTICS_SSC,
};

export const INTERACTED_CONFIG = {
  title: 'Interacted',
  total: 0,
  simpleView: false,
  description: 'Candidates',
  options: [],
  loading: false,
  empty: false,
};

export const ASSIGNED_CONFIG = {
  title: 'Assigned',
  total: 0,
  simpleView: false,
  description: 'Candidates',
  options: [],
  loading: false,
  empty: false,
};

export const JOB_OFFERS_CONFIG = {
  title: 'Job offers',
  total: 0,
  simpleView: false,
  description: 'Offers made',
  options: [],
  loading: false,
  empty: false,
};

export const TOTAL_UNIQUE_CANDIDATE_CONFIG = {
  title: 'Total unique candidates',
  total: 0,
  simpleView: true,
  description: 'Candidates',
  options: [],
  loading: false,
  empty: false,
};

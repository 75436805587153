import React from 'react';
import { Tooltip } from '_atoms';
import { Priority } from '_atoms/Priority';
import { Status } from '_atoms/Status';
import { headerCellRender } from '../../Jobs/constants';
import './style.scss';

export const columns = (sort, setSorting) => [
  {
    title: headerCellRender('Priority', 'Priority', 'priority', sort, setSorting),
    dataIndex: 'priority',
    key: 'priority',
    width: 100,
    sorter: true,
    className: 'analytics-openings__priority',
    render: data => <Priority priority={data} />,
  },
  {
    title: headerCellRender('Job name', 'Job name', 'job_name', sort, setSorting),
    dataIndex: 'job_name',
    key: 'job_name',
    width: 200,
    sorter: true,
    className: 'analytics-openings__job-name',
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: headerCellRender('Team Lead', 'Team Lead', 'team_lead', sort, setSorting),
    dataIndex: 'team_lead',
    key: 'team_lead',
    width: 200,
    sorter: true,
    className: 'analytics-openings__team-lead',
  },
  {
    title: headerCellRender('Expected date', 'Expected date', 'expected_date', sort, setSorting),
    dataIndex: 'expected_date',
    key: 'expected_date',
    width: 140,
    sorter: true,
    className: 'analytics-openings__expected-date',
  },
  {
    title: headerCellRender(
      'Required experience level',
      'Required experience level',
      'technical_level',
      sort,
      setSorting
    ),
    dataIndex: 'technical_level',
    key: 'technical_level',
    width: 180,
    sorter: true,
    className: 'analytics-openings__technical-level',
  },
  {
    title: headerCellRender('Status', 'Status', 'status', sort, setSorting),
    dataIndex: 'status',
    key: 'status',
    width: 160,
    sorter: true,
    className: 'analytics-openings__status',
    render: data => <Status type={data.type}>{data.text}</Status>,
  },
  {
    title: headerCellRender('Creation date', 'Creation date', 'creation_date', sort, setSorting),
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 140,
    sorter: true,
    className: 'analytics-openings__creation-date',
  },
  {
    title: headerCellRender('Office location', 'Office location', 'office_location', sort, setSorting),
    dataIndex: 'office_location',
    key: 'office_location',
    width: 180,
    sorter: true,
    className: 'analytics-openings__office-location',
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: 'Closure date',
    dataIndex: 'closure_date',
    key: 'closure_date',
    width: 140,
    sorter: true,
    className: 'analytics-openings__closure-date',
  },
  {
    title: headerCellRender(
      'Potential candidates for the opening',
      'Potential candidates for the opening',
      'potential_candidates',
      sort,
      setSorting
    ),
    dataIndex: 'potential_candidates',
    key: 'potential_candidates',
    width: 180,
    sorter: true,
    className: 'analytics-openings__potential-candidates',
  },
  {
    title: headerCellRender(
      'Candidates assigned to the job',
      'Candidates assigned to the job',
      'assigned_candidates_count',
      sort,
      setSorting
    ),
    dataIndex: 'assigned_candidates_count',
    key: 'assigned_candidates_count',
    width: 180,
    sorter: true,
    className: 'analytics-openings__assigned-candidates-count',
  },
];
